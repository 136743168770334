.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #2c5530;
  color: #f5f5f5;
  padding: 2rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  color: #e8f0e9;
}

.main-content {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #f7f9f8;
  color: #2c3e50;
  line-height: 1.6;
  font-size: 1.1rem;
}

.about-section,
.youtube-section {
  margin-bottom: 3rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.youtube-button {
  display: inline-block;
  background-color: #3b7b9b;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s;
  font-size: 1.1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.youtube-button:hover {
  background-color: #2c5d77;
}

.footer {
  background-color: #2c5530;
  color: #f5f5f5;
  padding: 1rem;
  margin-top: auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h2 {
  color: #2c5530;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

p {
  margin-bottom: 1.2rem;
}

.google-form-container {
  margin: 2rem auto;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.google-form {
  width: 100%;
  height: 500px;  /* Adjust based on your form length */
  border: none;
}

@media (max-width: 600px) {
  .google-form {
    height: 600px;  /* Slightly taller on mobile */
  }
}
